:root {
	background: #000000;
}
.index_footer{
	position: relative;
	bottom: 0;
	left: 0;
	right: 0;
	height: 5vh;
	background-color: #000000;
}