.footer{
	/* display: none; */
	background-color: #000000;
	display: flex;
	flex-direction: column;
	padding-bottom: 3vw;
	margin: auto;
	position: static;
  	left: 0;
  	bottom: 0;
  	width: 100%;
}

.col{
	margin: auto;
}

.col-one{
	display: flex;
	flex: row;
	align-items: center;
	margin: auto;
}

.footer_logos{
	width: 2.7vw; /*changed this*/
	height: auto;
	margin: 1.5vw;
	color: #ffffff;
	margin-top: 4vw;
}

.faLinkedin:hover{
	color: #0072b1;
}

.faGithub:hover{
	color: #171515;
}

.faEnvelope:hover{
	color: #e7c9a9;
}

.faSpotify:hover{
	color: #1DB954;
}

.faTwitter:hover{
	color: #1DA1F2;
}


.footer p{
	font-family: 'M PLUS Rounded 1c';
	color: #ffffff;
	font-size: 1vw;
}

@media (max-width:760px){
	.footer_logos{
		margin-top: 2vw;
	}
	
}
/* @media (min-width:760px) {
	.footer{
		background-color: #000000;
		display: flex;
		flex-direction: column;
		padding-bottom: 1vw;
		margin: auto;
		position: static;
	  	left: 0;
	  	bottom: 0;
	  	width: 100vw;
	}

	.footer-content{
		display: flex;
		flex-direction: row;
		margin-left: 15vw;
		margin-right: 15vw;
		margin-top: 3vw;
	}

	.footer_logos{
		width: 2vw; 
		margin: 1vw; 
	}
} */

