.copright{
    background-color: #000000;
	display: flex;
	flex-direction: column;
	padding-bottom: 3vw;
	margin: auto;
	position: static;
  	left: 0;
  	bottom: 0;
  	width: 100%;
}

.copyright p{
    background-color: #000000;
    margin-top: -0.1vw;
    padding-top: 2vw;
    font-family: 'M PLUS Rounded 1c';
	color: #ffffff;
	font-size: 1vw;
    padding-bottom: 3vw;
}

@media (max-width:760px) {
	.copyright p{
		font-size: 2vw;
	}
}