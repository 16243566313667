@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;700&display=swap');

.index_body{
	background-image: linear-gradient(to bottom, #000000, #000000, #000000,
		#250d18, #400e2d, #5a0e48, #71116a, #76107c, #791390, #771aa6, 
		#6916aa, #5814af, #4114b3, #1716b8, #000000);
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100vw;
	overflow-x: hidden;
	justify-content: center;
}

.text{
	font-family: 'M PLUS Rounded 1c', sans-serif;
    color: white;
    position: absolute;
    margin: auto;
    z-index: 5;
    line-height: 1;
	/* margin-left: 28vw; */
}


.title-page-name{
	margin-top: 1vw;
	font-size: 5vw;
	font-weight: 700;
}

.text h2{
	font-size: 3vw;
}

.Raunak-Anand{
	margin-top: -20vw;
	padding: 0.5vw;
	width: 35vw;
	height: auto;
	border-radius: 50vw;
	border: 0.3vw solid white;
	margin-bottom: 2vw;
}

.first-page-logos{
	color: white;
	height: 5vw;
	margin-left: 3vw;
	margin-right: 3vw;
}

.fp-fa-Linkedin:hover{
	color: #0072b1;
}

.fp-fa-Github:hover{
	color: #171515;
}

.fp-fa-Envelope:hover{
	color: #e7c9a9;
}

.fp-fa-Spotify:hover{
	color: #1DB954;
}

.fp-fa-Twitter:hover{
	color: #1DA1F2;
}

#particles-js{
	margin-top: 14vh;
	height: 86vh;
	width: 100vw;
}

@media (min-width:760px) {
	.text{
		margin: auto;

	}
	.title-page-name{
		margin-top: 0vw;
		font-size: 3vw;
	}

	.text h2{
		font-size: 1.5vw;
	}

	.Raunak-Anand{
		width: 16vw;
		margin-top: 0vw;
	}

	.first-page-logos{
		height: 2.7vw;
		margin: 1vw;
		margin-left: 1.7vw;
		margin-right: 1.7vw;
	}

}