@import url(https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

:root {
	background: #000000;
}
.index_footer{
	position: relative;
	bottom: 0;
	left: 0;
	right: 0;
	height: 5vh;
	background-color: #000000;
}
.index_body{
	background-image: linear-gradient(to bottom, #000000, #000000, #000000,
		#250d18, #400e2d, #5a0e48, #71116a, #76107c, #791390, #771aa6, 
		#6916aa, #5814af, #4114b3, #1716b8, #000000);
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100vw;
	overflow-x: hidden;
	justify-content: center;
}

.text{
	font-family: 'M PLUS Rounded 1c', sans-serif;
    color: white;
    position: absolute;
    margin: auto;
    z-index: 5;
    line-height: 1;
	/* margin-left: 28vw; */
}


.title-page-name{
	margin-top: 1vw;
	font-size: 5vw;
	font-weight: 700;
}

.text h2{
	font-size: 3vw;
}

.Raunak-Anand{
	margin-top: -20vw;
	padding: 0.5vw;
	width: 35vw;
	height: auto;
	border-radius: 50vw;
	border: 0.3vw solid white;
	margin-bottom: 2vw;
}

.first-page-logos{
	color: white;
	height: 5vw;
	margin-left: 3vw;
	margin-right: 3vw;
}

.fp-fa-Linkedin:hover{
	color: #0072b1;
}

.fp-fa-Github:hover{
	color: #171515;
}

.fp-fa-Envelope:hover{
	color: #e7c9a9;
}

.fp-fa-Spotify:hover{
	color: #1DB954;
}

.fp-fa-Twitter:hover{
	color: #1DA1F2;
}

#particles-js{
	margin-top: 14vh;
	height: 86vh;
	width: 100vw;
}

@media (min-width:760px) {
	.text{
		margin: auto;

	}
	.title-page-name{
		margin-top: 0vw;
		font-size: 3vw;
	}

	.text h2{
		font-size: 1.5vw;
	}

	.Raunak-Anand{
		width: 16vw;
		margin-top: 0vw;
	}

	.first-page-logos{
		height: 2.7vw;
		margin: 1vw;
		margin-left: 1.7vw;
		margin-right: 1.7vw;
	}

}
.copright{
    background-color: #000000;
	display: flex;
	flex-direction: column;
	padding-bottom: 3vw;
	margin: auto;
	position: static;
  	left: 0;
  	bottom: 0;
  	width: 100%;
}

.copyright p{
    background-color: #000000;
    margin-top: -0.1vw;
    padding-top: 2vw;
    font-family: 'M PLUS Rounded 1c';
	color: #ffffff;
	font-size: 1vw;
    padding-bottom: 3vw;
}

@media (max-width:760px) {
	.copyright p{
		font-size: 2vw;
	}
}
:root {
	background: #000000;
}
.aboutMe_body{
	background-image: linear-gradient(to bottom, #000000, #000000, 
		#250d18, #400e2d, #5a0e48, #71116a, #76107c, #791390, #771aa6, 
		#6916aa, #5814af, #4114b3, #1716b8, #000000);
	display: flex;
	flex-direction: column;
	text-align: left;
	padding-bottom: 7vw;
}

.aboutMe_body h1{
	margin-top: 17vh;
	font-family: 'M PLUS Rounded 1c', sans-serif;
	color: white;
	margin-left: 8vw;
	font-size: 3.5vw;

}

.work_experience{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.block{
	display: flex;
	flex-direction: row;
	background-color: black;
	text-align: right;
	margin: auto;
	align-items: center;
	width: 85vw;
	border-radius: 2vw;
	margin-top: 2vw;
	margin-bottom: 2vw; 
	height: 15vw;
	cursor: pointer;
}

.block img{
	margin-top: 2vw;
	margin-bottom: 2vw;
	width: 10vw;
	margin-left: 5vw;
}

.block_content{
	text-align: right;
	margin: auto;
	color: white;
	margin-right: 4vw;
	line-height: 1;
}

.block_content h3{
	font-size: 2vw;
	font-family: 'M PLUS Rounded 1c', sans-serif;
	font-weight: 700;
	margin-right: 2vw;
}

.block_content h4{
	font-size: 1.5vw;
	font-family: 'M PLUS Rounded 1c', sans-serif;
	font-weight: 400;
	margin-right: 2vw;
}

.include{
	border-radius: 50%;
}

/*-----------------------------------------------------------------------------------*/

.workPopUp {
    position: fixed;
    left: 20vw;
    top: 2vw;
    right: 20vw;
    z-index: 2;
    display: flex;
    flex-direction: column;
    height:  90vh;
    background-color: black;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    color: #000000;
    border-radius: 15px;
    box-shadow: 0vw 0vw 0.25vw 0.25vw #ffffff;
}

.workPopUp-content{
	display: flex;
	flex-direction: column;
	margin-top: -1vw;
}

.workPopUp button {
    font-size: 2vw;
    align-self: flex-end;
    color: white;
    background-color: unset;
    padding: 1.5vw;
    border: unset;
    cursor: pointer;

}

.masterclass-pop-up, .womenindata-pop-up, .include-pop-up{
	width: 12vw;
	margin-left: 4vw;
	margin-right: 3vw;
}

.include-pop-up{
	border-radius: 50%;
}

.its-pop-up, .sachacks-pop-up{
	width: 15vw;
	margin-left: 4vw;
	margin-right: 3vw;
}

.sachacks-pop-up{
	padding-top: 3vw;
	height: 4vw;
}

.airtel-pop-up{
	width: 10vw;
	margin-left: 4vw;
	margin-right: 3vw;
}

.renewbuy-pop-up{
	width: 12vw;
	padding-top: 2vw;
	margin-left: 4vw;
	margin-right: 3vw;
}

.microsoft-pop-up{
	width: 17vw;
	padding-top: 2vw;
	margin-left: 4vw;
	margin-right: 2vw;
}

.workPopUp-first-row{
	display: flex;
	flex-direction: row;
}

.work-intro{
	display: flex;
	flex-direction: column;
	color: white;
	font-family: 'M PLUS Rounded 1c', sans-serif;
	line-height: 1;
}

.workPopUp-first-row{
	margin-bottom: 3vw;
}

.work-intro h3{
	font-size: 2vw;
	margin-bottom: -1vw;
	font-weight: 700;
}

.work-intro h4{
	font-size: 1.5vw;
	margin-bottom: -1vw;
	font-weight: 400;
}

.work-intro h5{
	font-size: 1vw;
	margin-bottom: -1vw;
}

.workPopUp-second-row ul, .workPopUp-content a{
	color: white;
	font-family: 'M PLUS Rounded 1c', sans-serif;
	font-size: 1.2vw;
	margin-right: 1vw;
	margin-left: 1vw;
}

.workPopUp-content a{
	margin-left: 2vw;
}

@media (max-width:760px) {
	.aboutMe_body h1{
		font-size: 5vw;
	}

	.block{
		height: 20vw;;
	}

	.block img{
		width: 14vw;
	}

	.block_content h3{
		font-size: 2.5vw;
	}

	.block_content h4{
		font-size: 2vw;
	}

	.workPopUp {
		top: 19vw;
		left: 10vw;
		right: 10vw;
	}

	.workPopUp button{
		font-size: 8vw;
		margin: 2vw;
	}

	.masterclass-pop-up, .womenindata-pop-up, .include-pop-up{
		width: 17vw;
	}

	.work-intro h3{
		font-size: 2.5vw;
	}

	.work-intro h4{
		font-size: 1.8vw;
	}

	.work-intro h5{
		font-size: 1.5vw;
	}

	.workPopUp-second-row ul, .workPopUp-content a{
		font-size: 2vw;
		margin-right: 2vw;
		margin-left: 2vw;
	}

	.workPopUp-content a{
		margin-left: 4vw;
	}

	.its-pop-up, .sachacks-pop-up{
		width: 20vw;
	}

	.sachacks-pop-up{
		padding-top: 5vw;
		height: 5vw;
	}

	.airtel-pop-up{
		width: 14vw;
	}

	.renewbuy-pop-up{
		width: 20vw;
	}
}
.footer{
	/* display: none; */
	background-color: #000000;
	display: flex;
	flex-direction: column;
	padding-bottom: 3vw;
	margin: auto;
	position: static;
  	left: 0;
  	bottom: 0;
  	width: 100%;
}

.col{
	margin: auto;
}

.col-one{
	display: flex;
	flex: row 1;
	align-items: center;
	margin: auto;
}

.footer_logos{
	width: 2.7vw; /*changed this*/
	height: auto;
	margin: 1.5vw;
	color: #ffffff;
	margin-top: 4vw;
}

.faLinkedin:hover{
	color: #0072b1;
}

.faGithub:hover{
	color: #171515;
}

.faEnvelope:hover{
	color: #e7c9a9;
}

.faSpotify:hover{
	color: #1DB954;
}

.faTwitter:hover{
	color: #1DA1F2;
}


.footer p{
	font-family: 'M PLUS Rounded 1c';
	color: #ffffff;
	font-size: 1vw;
}

@media (max-width:760px){
	.footer_logos{
		margin-top: 2vw;
	}
	
}
/* @media (min-width:760px) {
	.footer{
		background-color: #000000;
		display: flex;
		flex-direction: column;
		padding-bottom: 1vw;
		margin: auto;
		position: static;
	  	left: 0;
	  	bottom: 0;
	  	width: 100vw;
	}

	.footer-content{
		display: flex;
		flex-direction: row;
		margin-left: 15vw;
		margin-right: 15vw;
		margin-top: 3vw;
	}

	.footer_logos{
		width: 2vw; 
		margin: 1vw; 
	}
} */


